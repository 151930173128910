import { ModalStatus, Text, useModalState } from "@clipboard-health/ui-react";
import { Button, DialogContent, Stack } from "@mui/material";
// eslint-disable-next-line no-restricted-imports
import {
  cancelNFCScan,
  deviceNFCCapabilityForShift,
  readNFCTagInfo,
} from "@src/app/hcpShifts/components/nfc/nfcHelper";
import { FullScreenDialog } from "@src/appV2/lib";
// eslint-disable-next-line no-restricted-imports
import { DeviceNFCCapabilityForShift } from "@src/lib/interface/src";
import { useRef, useState } from "react";

export function OldNfcPluginDebugger() {
  const modalState = useModalState(ModalStatus.CLOSED);

  const [readValue, setReadValue] = useState<string>();
  const [readError, setReadError] = useState<string>();
  const [readerState, setReaderState] = useState<"idle" | "reading" | "stopped">("idle");

  const canTimeoutRef = useRef(true);

  const startReading = async () => {
    setReaderState("reading");

    const nfcCompatibility = await deviceNFCCapabilityForShift();
    if (nfcCompatibility !== DeviceNFCCapabilityForShift.NFC_ENABLED) {
      setReaderState("stopped");
      setReadError(`Known: ${nfcCompatibility as string}`);
      return;
    }

    canTimeoutRef.current = true;
    const timeoutRef = setTimeout(async () => {
      if (!canTimeoutRef.current) {
        return;
      }

      await cancelNFCScan();
      setReaderState("stopped");
      setReadValue(undefined);
      setReadError("Known: TIMEOUT");
    }, 5000);

    try {
      await readNFCTagInfo(async (readInfo) => {
        setReaderState("idle");
        setReadError(undefined);
        setReadValue(readInfo);

        canTimeoutRef.current = false;
        clearTimeout(timeoutRef);

        await cancelNFCScan();
      });
    } catch (error) {
      setReaderState("idle");
      setReadValue(undefined);
      setReadError(`Unknown: ${(error as { message: string }).message}`);

      canTimeoutRef.current = false;
      clearTimeout(timeoutRef);
    }
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={modalState.openModal}>
        Open Old NFC debugger
      </Button>
      <FullScreenDialog modalState={modalState}>
        <DialogContent>
          <Stack spacing={2}>
            <Text variant="h2">Old NFC Plugin Debugger</Text>
            <Text>State: {readerState}</Text>
            <Text>Read Value: ({readValue ?? "undefined"})</Text>
            <Text>Error: ({readError ?? "undefined"})</Text>
            <Text>
              <em>Will timeout after 5 seconds</em>
            </Text>

            <Button
              variant="contained"
              onClick={async () => {
                await startReading();
              }}
            >
              Start reader
            </Button>
            <Button
              variant="contained"
              onClick={async () => {
                canTimeoutRef.current = false;
                await cancelNFCScan();
                setReaderState("stopped");
                setReadValue(undefined);
                setReadError("GRACEFUL_STOP");
              }}
            >
              Stop reader
            </Button>
            <Button variant="outlined" color="secondary" onClick={modalState.closeModal}>
              Close
            </Button>
          </Stack>
        </DialogContent>
      </FullScreenDialog>
    </>
  );
}
