import { type ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { NotFoundPage } from "../lib";
import { DebugPage } from "./Page";
import { PullToRefreshDebugPage } from "./Pages/PullToRefresh/Page";

export function DebugRoutes(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path}>
        <DebugPage />
      </Route>
      <Route exact path={`${match.path}/pull-to-refresh`}>
        <PullToRefreshDebugPage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
