import { Image, Title } from "@clipboard-health/ui-react";
import { IonPage } from "@ionic/react";
import { Box, Stack } from "@mui/material";
import { FullScreenPageLayout } from "@src/appV2/lib";
import { type ReactElement } from "react";

import fluffyMonsterLoadingImage from "./assets/fluffyMonsterLoading.png";

export function E2ELoadingPage(): ReactElement {
  return (
    <IonPage>
      <FullScreenPageLayout>
        <Box
          sx={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Stack alignItems="center" direction="column" spacing={1}>
            <Title bold component="h1">
              Hang in there!
            </Title>
            <Title component="h2">Setting up test scenario...</Title>
            <br />
            <Image src={fluffyMonsterLoadingImage} alt="Loading test scenario" width={360} />
          </Stack>
        </Box>
      </FullScreenPageLayout>
    </IonPage>
  );
}
