interface DebugErrorProps {
  shouldThrowError: boolean;
}
export function DebugErrorPage(props: DebugErrorProps) {
  const { shouldThrowError } = props;
  if (shouldThrowError) {
    throw new Error("Simulating a Debug Error On Purpose.");
  }

  return null;
}
