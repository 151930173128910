import { LoadingButton } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";
import {
  DeploymentEnvironmentName,
  environmentConfig,
  isDevelopmentNodeEnvironment,
} from "@src/appV2/environment";
import { useToast } from "@src/appV2/lib";

import { useGetAgentOtpInDevelopmentMode } from "../api/useGetAgentOtpInDevelopmentMode";

interface FetchOtpButtonInDevelopmentModeProps {
  phoneNumber?: string;
  email?: string;
  onSuccess: (otp: string) => void;
}

/*
  This button is only displayed in development environment when the environment variable REACT_APP_TEST_HELPER_API_KEY exists.
  It is used to quickly autofill the OTP for testing purposes.
*/
function FetchOtpButtonInDevelopmentMode(props: FetchOtpButtonInDevelopmentModeProps) {
  const { phoneNumber, email, onSuccess } = props;
  const { mutateAsync: fetchOtpForDevelopment, isLoading: isFetchingOtpForDevelopment } =
    useGetAgentOtpInDevelopmentMode();
  const { showErrorToast } = useToast();

  if (
    !isDevelopmentNodeEnvironment() ||
    !isDefined(process.env.REACT_APP_TEST_HELPER_API_KEY) ||
    environmentConfig.REACT_APP_ENVIRONMENT_NAME === DeploymentEnvironmentName.PRODUCTION
  ) {
    return null;
  }

  return (
    <Box sx={{ py: 2 }}>
      <LoadingButton
        isLoading={isFetchingOtpForDevelopment}
        variant="contained"
        onClick={async () => {
          try {
            const otp = await fetchOtpForDevelopment(
              isDefined(phoneNumber) ? { phoneNumber } : { email }
            );
            onSuccess(otp);
          } catch {
            showErrorToast("Something went wrong while fetching the OTP code");
          }
        }}
      >
        Autofill OTP 🔑
      </LoadingButton>
    </Box>
  );
}

export default FetchOtpButtonInDevelopmentMode;
