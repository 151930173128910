import { Text } from "@clipboard-health/ui-react";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { type ReactElement, useState } from "react";

export function PullToRefreshDebugPage(): ReactElement {
  const [pullCount, setPullCount] = useState(0);
  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Pull To Refresh Debugger"
          leftCta={<BackButtonLink defaultBackTo="/home-v2/debug" />}
        />
      }
      onRefresh={async () => {
        await new Promise<void>((resolve) => {
          setTimeout(resolve, 100 + 1000 * Math.random());
        });
        setPullCount((lastPullCount) => lastPullCount + 1);
      }}
    >
      <Text>Pull to Refresh Count: {pullCount}</Text>
    </PageWithHeader>
  );
}
