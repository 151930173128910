import { useModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { NfcHashValidationAction } from "@src/appV2/Shifts/NfcHashes/api/types";
import { NfcScanDialog } from "@src/appV2/Shifts/NfcHashes/NfcDialog/NfcScanDialog";
import { NfcTagRequestStatus } from "@src/appV2/Shifts/Shift/types";
import { useMemo } from "react";

/**
 * This is a temporary debug page to test and debug the NFC scanner
 * before the actual release (TX-1533, TX-1534).
 *
 * This page should be removed once we are ready to roll this out
 */
export function NfcScannerDebugPage() {
  const modalState = useModalState();
  const { showInfoToast } = useToast();

  const randomNfcTagRequests = useMemo(() => {
    const predefinedTags = [
      { tagLocation: "Second Floor", tagStatus: NfcTagRequestStatus.SET_UP },
      { tagLocation: "Service Desk", tagStatus: NfcTagRequestStatus.SET_UP },
    ];
    const numberOfTags = Math.floor(Math.random() * (predefinedTags.length + 1));
    return Array.from({ length: numberOfTags }, (_, index) => {
      return predefinedTags[index % predefinedTags.length];
    });
  }, []);

  return (
    <>
      <Button
        fullWidth
        size="small"
        variant="outlined"
        color="warning"
        sx={{ color: "warning.main" }}
        onClick={modalState.openModal}
      >
        Debug NFC Scanner Dialog
      </Button>
      <NfcScanDialog
        modalState={modalState}
        clockAction={NfcHashValidationAction.CLOCK_OUT}
        metaProps={{
          workerId: "67164818edc204d118670b8e",
          workplaceId: "67164818edc204d118670b8e",
          workplaceName: "Old Town Hospital",
        }}
        nfcTagRequests={randomNfcTagRequests}
        shiftId="67164818edc204d118670b8e"
        onSuccess={() => {
          showInfoToast("Received onSuccess call");
          modalState.closeModal();
        }}
        onClose={() => {
          modalState.closeModal();
        }}
        onConvertToNonInstantPay={() => {
          showInfoToast("Received onConvertToNonInstantPay call");
          modalState.closeModal();
        }}
      />
    </>
  );
}
