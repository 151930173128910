import { isDefined } from "@clipboard-health/util-ts";
import { extractPhoneNumberDigits } from "@src/appV2/lib";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { environmentConfig, isDevelopmentNodeEnvironment } from "../../environment";

interface AgentOtpInDevelopmentModeParams {
  phoneNumber?: string;
  email?: string;
}

// This hook is only for use in development mode. It fetches an OTP for a given phone number
// using a test helper API. It is not for use outside of development environment.
export function useGetAgentOtpInDevelopmentMode() {
  return useMutation({
    mutationFn: async (params: AgentOtpInDevelopmentModeParams): Promise<string> => {
      const { phoneNumber, email } = params;

      if (!isDevelopmentNodeEnvironment()) {
        throw new Error("useGetAgentOtpInDevelopmentMode can only be used in development mode");
      }

      if (!isDefined(process.env.REACT_APP_TEST_HELPER_API_KEY)) {
        throw new Error("REACT_APP_TEST_HELPER_API_KEY is not defined in process.env");
      }

      const response = await axios.post<{ otp: string }>(
        `${environmentConfig.REACT_APP_BASE_API_URL}/testHelpers/e2e/otp`,
        null,
        {
          headers: {
            Authorization: process.env.REACT_APP_TEST_HELPER_API_KEY,
          },
          params: isDefined(phoneNumber)
            ? {
                phoneNumber: extractPhoneNumberDigits(phoneNumber),
              }
            : { email },
        }
      );

      return response.data.otp;
    },
  });
}
