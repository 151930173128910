import { BaseLink } from "@clipboard-health/ui-react";
import { IonIcon } from "@ionic/react";
import { Box, Card, CardActionArea, CardContent } from "@mui/material";
import { type LocationDescriptor } from "history";
import { chevronForward } from "ionicons/icons";
import { type ReactElement, type ReactNode } from "react";

interface DebugCard {
  id: number;
  content: ReactNode;
  to: LocationDescriptor;
}

interface Props {
  cards: DebugCard[];
}

export function DebugCards(props: Props): ReactElement {
  const { cards } = props;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {cards.map((card) => {
        const { id, content, to } = card;
        return (
          <Card key={id}>
            <CardActionArea component={BaseLink} to={to}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {content}
                  <IonIcon icon={chevronForward} />
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        );
      })}
    </Box>
  );
}
