import { type ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { E2EAuthPage } from "./AuthPage";
import { E2ELoadingPage } from "./LoadingPage";

export function E2ERoutes(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/loading`}>
        <E2ELoadingPage />
      </Route>
      <Route exact path={`${match.url}/auth`}>
        <E2EAuthPage />
      </Route>
    </Switch>
  );
}
